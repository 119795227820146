import { ReactElement } from "react";

const Schema = ({ url, name }: { url: string; name: string }): ReactElement => {
    return (
        <script
            type="application/ld+json"
            dangerouslySetInnerHTML={{
                __html: `{
                            "@context": "https://schema.org",
                            "@graph": [
                                {
                                    "@type": "WebSite",
                                    "name": "${name}",
                                    "url": "${url}",
                                    "publisher": {
                                        "@type": "Organization",
                                        "name": "${name}",
                                        "url": "${url}",
                                        "logo": {
                                            "@type": "ImageObject",
                                            "url": "${url}/logo.svg"
                                        }
                                    }
                                }
                            ]
                        }`,
            }}
        />
    );
};
export default Schema;

import React, { ReactElement } from "react";
import dynamic from "next/dynamic";
import { useDomainContext } from "@hooks/useDomainContext";

const UserLocation = dynamic(() => import("../userLocation"), { ssr: false });
import { getHomepageHref } from "@lib/sharedUtils";
import { useRouter } from "next/router";

export default function Header({
    withFlag,
}: {
    withFlag?: boolean;
}): ReactElement {
    const { visitDetails, domain, locale, defaultLocale } = useDomainContext();
    const { query } = useRouter();

    return (
        <header>
            {visitDetails?.city && visitDetails?.ip && (
                <UserLocation
                    location={visitDetails?.city}
                    ip={visitDetails?.ip}
                />
            )}
            <div className="inner max-w-7xl mx-auto px-4 py-4 flex justify-between items-center">
                <div className="logo w-52 md:w-56 lg:w-60">
                    <a href={getHomepageHref(query, locale, defaultLocale)}>
                        <img
                            className="w-full h-auto mx-auto lg:ml-0"
                            src={domain?.logo?.fullPath ?? "/logo.svg"}
                            alt="vpnpricecomparison logo"
                        />
                    </a>
                </div>
                {withFlag && (
                    <div className="flag">
                        <img
                            src={`https://flagcdn.com/w40/${visitDetails?.countryCode?.toLowerCase()}.png`}
                            srcSet={`https://flagcdn.com/w80/${visitDetails?.countryCode?.toLowerCase()}.png 2x`}
                            width="40"
                            alt="Bulgaria"
                        ></img>
                    </div>
                )}
            </div>
        </header>
    );
}

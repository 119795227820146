import { useDomainContext } from "@hooks/useDomainContext";
import React, { ReactElement } from "react";
import styles from "./vpnCategories.module.scss";

export default function VpnCategories(): ReactElement {
    const { domain } = useDomainContext();

    return (
        <div className="mt-8">
            <h3 className="text-xl font-medium mb-4">VPNs by category</h3>
            <div
                className={`horizontal-scroll md:justify-center ${styles["category-list"]}`}
            >
                {domain?.listings.map((item, index) => (
                    <div
                        key={index}
                        className={`group horizontal-scroll-child ${styles["category"]}`}
                    >
                        <div className={styles["inner"]}>
                            <a
                                href={`/listing/${item.slug}`}
                                className="w-full absolute left-0 top-0 h-full flex flex-col items-start pb-3 pt-6 lg:pt-4 px-5 justify-between"
                            >
                                <h4 className="w-full">{item.name}</h4>
                                {item.icon && (
                                    <span>
                                        <img
                                            src={item.icon.fullPath}
                                            alt={item.name}
                                        />
                                    </span>
                                )}
                            </a>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
}

import React, { ReactElement } from "react";
import Image from "next/image";
import VpnCategories from "../vpnCategories";
import styles from "./mainSection.module.scss";

export default function MainSection({
    contactUs,
}: {
    contactUs?: boolean;
}): ReactElement {
    return (
        <section className={`${styles["main-section"]} mb-10`}>
            <div className="md:text-center lg:text-left max-w-xl md:max-w-3xl lg:max-w-7xl mx-auto lg:flex lg:px-4 lg:w-full lg:flex-row-reverse lg:mt-9 z-10">
                <div className={`${styles["image"]} lg:w-1/2`}>
                    <Image
                        src="/vpnpricecomparison/assets/images/vpnpricecomparison-main-section-imageOpt.png"
                        layout="intrinsic"
                        width={624}
                        height={409.31}
                        quality={75}
                        alt="vpnpricecomparison"
                    />
                </div>
                <div
                    className={`lg:w-1/2 lg:pr-9 ${
                        contactUs ? "flex flex-col justify-center" : ""
                    }`}
                >
                    <h1 className="text-2xl lg:text-3xl font-bold mb-2">
                        {contactUs ? "Contact Us" : "Choose your next VPN"}
                    </h1>
                    <p className="text-base font-light lg:text-lg lg:w-4/5">
                        {contactUs ? (
                            "We are open to discuss potential opportunities."
                        ) : (
                            <>Read what the experts think...</>
                        )}
                    </p>
                    {!contactUs && <VpnCategories />}
                </div>
            </div>
        </section>
    );
}
